<template>
  <default>
    <div class="tw-mb-4 c-blue-text">
      <v-chip-group mandatory active-class="primary">
        <!-- Dashboard chip hidden on small screens -->
        <v-chip active-class="false" :to="{ name: 'Dashboard' }">
          Dashboard
        </v-chip>
        <span class="tw-mx-2 mt-2"> > </span>

        <v-chip active-class="false" :to="{ name: 'Products' }">
          Products
        </v-chip>
        <span class="tw-mx-2 mt-2"> > </span>

        <v-chip  :to="{ name: 'TermAssurancePlan' }">{{ $options.name }}</v-chip>
      </v-chip-group>
    </div>

    <!-- Main Card for Term Assurance Plan Calculator -->
    <v-card elevation="2"
      class="main-card tw-px-4 md:tw-px-8 tw-py-6 md:tw-py-8 tw-rounded-lg tw-shadow-lg tw-bg-white">
      <!-- Title Section with Icon -->
      <div class="tw-flex tw-items-center tw-mb-6">
        <img src="/images/icons/top-icon.png" alt="Term Assurance Icon" class="tw-w-10 tw-h-10 tw-mr-3" />
        <h2 class="tw-text-2xl tw-font-semibold c-blue-text">Term Assurance Plan Calculator</h2>
      </div>

      <!-- Form Container -->
      <div
        class="form-container tw-w-full tw-flex tw-flex-wrap tw-py-4 md:tw-py-6 tw-bg-gray-50 tw-rounded-md tw-shadow-inner lg:tw-shadow-lg">
        <keep-alive>
          <component :is="current" :productData="productData" :benefits="benefits" :terms="terms"
            :quoteSummaryData="quoteSummaryData" @to-quote-summary="toQuoteSummary"></component>
        </keep-alive>
      </div>
    </v-card>
  </default>
</template>

<script>
import { mapState } from 'vuex';
import Default from '../../layouts/Default';
import CardTitle from '../../components/CardTitle';
import BackButton from '../../components/BackButton';
import QuoteSummary from '../../components/rfq/QuoteSummary';
import ClientQuotation from '../../components/rfq/ClientQuotation';
import ProductMixin from '@/mixins/ProductMixin';
import savedQuoteMixin from '@/mixins/savedQuoteMixin';

export default {
  created() {
    if (typeof window !== 'undefined' && window.gtag) {
      window.gtag('event', 'term_assurance_plan_page_visited_by_agent', {
        event_category: 'Term Assurance plan component created',
        event_label: 'term assurance plan component created',
      });
    }
  },
  mounted() {
    this.checkSavedQuote('termAssurance');
  },
  name: 'TermAssurancePlan',
  metaInfo: {
    title: 'RFQ - Term Assurance Plan',
  },
  components: {
    QuoteSummary,
    BackButton,
    CardTitle,
    Default,
    ClientQuotation,
  },
  mixins: [ProductMixin, savedQuoteMixin],
  data() {
    return {
      current: ClientQuotation,
      productData: { prodCode: 2016194, optionCode: 2016209 },
      benefits: [
        { coverName: 'Total & Permanent Disability', coverProdCode: 2013518 },
        { coverName: 'Waiver of Premium', coverProdCode: 2013517 },
        { coverName: 'Child Medical Reimbursement', coverProdCode: 2013568 },
        { coverName: 'Adult Medical Reimbursement', coverProdCode: 2013521 },
        { coverName: 'Critical Illness', coverProdCode: 2013520 },
        { coverName: 'Retrenchment', coverProdCode: 2016611 },
      ],
      terms: [5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20],
      quoteSummaryData: {},
    };
  },
  computed: {
    ...mapState({
      savedQuoteStatus: (state) => state.quote.isSaved,
    }),
  },
};
</script>
<style scoped>

.main-card {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  padding: 2rem;
}

.form-container {
  background-color: #f9fafb;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: inset 0 4px 8px rgba(0, 0, 0, 0.05);
}

.benefits-section {
  padding: 1.5rem;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
}

.benefits-section .tw-shadow-sm {
  transition: box-shadow 0.2s ease;
}

.benefits-section .tw-shadow-sm:hover {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
}

.v-checkbox__label {
  font-size: 0.9rem;
  color: #374151;
}
</style>
